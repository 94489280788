import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

const Partners: React.FC = () => {
  return (
    <Grid
      templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(6, 1fr)" }}
      gap={6}
      justifyItems="center"
      alignItems="center"
    >
      <GridItem maxW={36}>
        <StaticImage src="../images/partners/partner-1.png" alt="partner-1" />
      </GridItem>
      <GridItem maxW={36}>
        <StaticImage src="../images/partners/partner-2.png" alt="partner-2" />
      </GridItem>
      <GridItem maxW={36}>
        <StaticImage src="../images/partners/partner-3.png" alt="partner-3" />
      </GridItem>
      <GridItem maxW={36}>
        <StaticImage src="../images/partners/partner-4.png" alt="partner-4" />
      </GridItem>
      <GridItem maxW={36}>
        <StaticImage src="../images/partners/partner-5.png" alt="partner-5" />
      </GridItem>
      <GridItem maxW={36}>
        <StaticImage src="../images/partners/partner-6.png" alt="partner-6" />
      </GridItem>
    </Grid>
  );
};

export default Partners;
