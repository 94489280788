import React from "react";
import { HStack, VStack, Text, Image } from "@chakra-ui/react";

type Reviewer = {
  name: string;
  title: string;
  avatar: string;
};

type ReviewProps = {
  reviewer: Reviewer;
  message: string;
};

const Review: React.FC<ReviewProps> = props => {
  return (
    <HStack spacing={6} alignItems="flex-start">
      <Image src={props.reviewer.avatar} alt="" w={16} h={16} rounded="full" />

      <VStack spacing={4} alignItems="flex-start">
        <Text fontSize="xl" fontWeight="light">
          "{props.message}"
        </Text>

        <VStack spacing={0.5} alignItems="flex-start">
          <Text fontWeight="bold">{props.reviewer.name}</Text>
          <Text fontWeight="light">{props.reviewer.title}</Text>
        </VStack>
      </VStack>
    </HStack>
  );
};

export default Review;
