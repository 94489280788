import React from "react";
import { VStack, Text, Stack } from "@chakra-ui/react";
import Review from "./ui/Review";

const Reviews: React.FC = () => {
  return (
    <VStack spacing={10}>
      <Text fontSize="4xl" textAlign="center">
        Over 2.6 million interior designers and 8,000 companies are using
        AiHouse
      </Text>

      <Stack spacing={10} direction={{ base: "column", md: "row" }}>
        <Review
          reviewer={{
            name: "Aurelie Tshiama",
            title: "Founder, The Clamp",
            avatar: require("images/reviewers/user1.jpg").default,
          }}
          message="AiHouse simplifies 3D visualization process with a beginner-friendly interface. It helps my clients visualize their space in much more detail. How would it evolve next? The possibilities are endless."
        />
        <Review
          reviewer={{
            name: "Emily Green",
            title: "Interior Designer",
            avatar: require("images/reviewers/user2.jpg").default,
          }}
          message="AiHouse is a great software that can provide photorealistic images and panoramas in a much faster way than the conventional tools, saving lots of time in rendering."
        />
      </Stack>
    </VStack>
  );
};

export default Reviews;
