import React from "react";
import Helmet from "react-helmet";
import Slider, { Settings } from "react-slick";
import "./slick.css";

type CarouselProps = {
  children: React.ReactNode;
} & Settings;

const defaultSettings: Settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  infinite: true,
  dots: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Carousel: React.FC<CarouselProps> = ({ children, ...settings }) => {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <Slider {...defaultSettings} {...settings}>
        {children}
      </Slider>
    </>
  );
};

export default Carousel;
