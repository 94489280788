import React from "react";
import { Grid, Box, VStack, Text } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

const Features: React.FC = () => {
  return (
    <Grid
      gap={6}
      templateColumns={{
        base: "repeat(1, 1fr)",
        sm: "repeat(2, 1fr)",
        md: "repeat(4, 1fr)",
      }}
    >
      <Box
        borderColor="orange"
        borderWidth="1px"
        shadow="md"
        p={10}
        rounded="md"
        transform="translate(0, 0)"
        _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
        transitionProperty="all"
        transitionDuration="200ms"
        transitionTimingFunction="linear"
        userSelect="none"
      >
        <VStack spacing={4}>
          <StaticImage
            src="../images/icons/design-and-render.svg"
            alt="design and render icon aihouse india"
          />
          <Text fontWeight="medium" textAlign="center" className="text-xl">
            Design and Render
          </Text>
        </VStack>
      </Box>

      <Box
        borderColor="orange"
        borderWidth="1px"
        shadow="md"
        p={10}
        rounded="md"
        transform="translate(0, 0)"
        _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
        transitionProperty="all"
        transitionDuration="200ms"
        transitionTimingFunction="linear"
        userSelect="none"
      >
        <VStack spacing={4}>
          <StaticImage
            src="../images/icons/animation.svg"
            alt="animation icon aihouse india"
          />
          <Text fontWeight="medium" textAlign="center" className="text-xl">
            Animations
          </Text>
        </VStack>
      </Box>

      <Box
        borderColor="orange"
        borderWidth="1px"
        shadow="md"
        p={10}
        rounded="md"
        transform="translate(0, 0)"
        _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
        transitionProperty="all"
        transitionDuration="200ms"
        transitionTimingFunction="linear"
        userSelect="none"
      >
        <VStack spacing={4}>
          <StaticImage
            src="../images/icons/gallery.svg"
            alt="gallery icon aihouse india"
          />
          <Text fontWeight="medium" textAlign="center" className="text-xl">
            Brand Gallery
          </Text>
        </VStack>
      </Box>

      <Box
        borderColor="orange"
        borderWidth="1px"
        shadow="md"
        p={10}
        rounded="md"
        transform="translate(0, 0)"
        _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
        transitionProperty="all"
        transitionDuration="200ms"
        transitionTimingFunction="linear"
        userSelect="none"
      >
        <VStack spacing={4}>
          <StaticImage
            src="../images/icons/design-to-manufacture.svg"
            alt="design to manufacture icon aihouse india"
          />
          <Text fontWeight="medium" textAlign="center" className="text-xl">
            Design to Manufacture System
          </Text>
        </VStack>
      </Box>
    </Grid>
  );
};

export default Features;
